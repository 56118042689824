import { template as template_abef4e3d98e44266947174f705e75f2c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_abef4e3d98e44266947174f705e75f2c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
