import { template as template_d899d0e373f94494811c5b0f64d7a370 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_d899d0e373f94494811c5b0f64d7a370(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
